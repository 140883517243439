import React from "react";
import Cards from "../../components/Cards/Cards";
import Footer from "../../components/Footer/Footer";
import Goal from "../../components/Goal/Goal";
import { useState, useEffect } from "react";
import "./Home.css";
import useWindowDimensions from "../../utils/windowSize";

const Home = () => {
  const width = useWindowDimensions().width;
  const [star, setStar] = useState(0);
  const [starRotation, setStarRotation] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      let y = window.scrollY;
      setStar(y);
      setStarRotation(y / 3);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("show");
      } else {
        entry.target.classList.remove("show");
      }
    });
  });

  const hiddenElements = document.querySelectorAll(".hidden");
  hiddenElements.forEach((element) => {
    observer.observe(element);
  });

  return (
    <>
      <div className="header-margin">
        <section>
          <img
            className="star"
            id="star"
            src="./pictures/star.png"
            style={{
              top: `${width > 1500 ? star : 100}px`,
              transform: `translateY(-1000px) rotate(${starRotation}deg)`,
            }}
            alt="star"
          />
          <Goal />
        </section>
        <div className="home">
          <h2>Why Us?</h2>
          <div className="section">
            <p className="hidden side-text">
              Choosing Star gate, as your insurance consultant company, is
              joining a large group of customers, who have benefited from the
              knowledge and finest experiences and services that the company has
              provided them with for over two decades, through contracting with
              leading insurance companies in Lebanon coupled with
              professionalism and excellence in the field of insurance
              consultancy.
            </p>
            <p className="hidden side-text arab">
              اختيارك لشركة ستار غيت يعني انك اخترت ان تنضم الى مجموعة كبيرة من
              الزبائن استفادت من العلم والخبرة اللتين وفرتهما لهم الشركة على مدى
              اكثر من ثلاث عقود من الزمن مُضافاً اليهما ثقة اكبر واهم شركات
              التأمين في لبنان بتعاقدهم معنا نتيجة للحرفية والمصداقية اللتين
              نتميز بهما في مجالات الاستشارية التأمينية.
            </p>
          </div>
          <h2>Our Services</h2>
          <Cards />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
