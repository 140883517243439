import React from "react";
import "./Goal.css";

const Goal = () => {
  return (
    <div className="goal">
      <img
        alt=""
        src="https://i.ibb.co/P9ZLrkS/text2photo-902ffe83cd87aded4008ab7413d2fc18.jpg"
      />
      <p className="show hidden side-text">
        <strong>STARGATE S.A.R.L</strong> is a brokerage company specialized in
        insurance consultation, licensed by the ministry of economy and trade,
        seeking to ensure customer protection, through insurance contracts and
        policies at best prices, coverage and conditions through leading
        insurance companies in Lebanon.
      </p>
    </div>
  );
};

export default Goal;
