import React from "react";
import "./Value.css";

const Value = ({ engTitle, arabTitle, engP, arabP }) => {
  return (
    <div className="value hover">
      <div>
        <h3>{engTitle}</h3>
        <p>{engP}</p>
      </div>
      <div className="arab">
        <h3>{arabTitle}</h3>
        <p>{arabP}</p>
      </div>
    </div>
  );
};

export default Value;
