import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>
      <ul className="social-icons">
        <li>
          <a
            href="https://www.facebook.com/stargateinsurancelb"
            target={"_blank"}
          >
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@StarGateLebanon" target={"_blank"}>
            <ion-icon name="logo-youtube"></ion-icon>
          </a>
        </li>
        <li>
          <a href="mailto:info@stargate-broker.com" target={"_blank"}>
            <ion-icon name="mail-outline"></ion-icon>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/stargate.broker/"
            target={"_blank"}
          >
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
        </li>
        {/* <li>
          <a>
            <ion-icon name="call-outline"></ion-icon>
          </a>
        </li>
        <li>
          <a>
            <ion-icon name="phone-portrait-outline"></ion-icon>
          </a>
        </li>
        <li>
          <a>
              <ion-icon name="time-outline"></ion-icon>
          </a>
        </li> */}
      </ul>
      <ul className="menu">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/services">Services</a>
        </li>
        <li>
          <a href="/contact">Contact us</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
