import React from "react";
import Card from "./Card";

const Cards = ({}) => {
  return (
    <div className="cards-container">
      <Card
        frontImage={
          "https://cdn.pixabay.com/photo/2016/08/25/20/14/crash-test-1620591_960_720.jpg"
        }
        engTitle={"Car & Vehicules"}
        // engText="IPl BODILY INJURIES (compulsory insurance): covers bodily injuries
        // caused to third parties] IPl MAIERIAI DAMAGE: covers material
        // damages caused to third parties with a reliable assistance
        // service, including personal accident coverage tor driver and
        // Dassengers/ MOTOR All RISK: comprehensive car insurance cover witn
        // a relia- ble assistance service, including personal accident
        // coverage tor driver and Dassen- gers/ ORANGE CARDS: Syria 8 Arabic
        // countries/ ROAD ASSISSIANCE: wnetner it's a flat tire or a
        // breakdown we're nere to assist you witn a-best-in-class road
        // assis- tance 8 towing service."
        engText={
          <ul>
            <li>1. Lebanese compulsory policy</li>
            <li>2. Third party liability</li>
          </ul>
        }
        arabText={
          <ul>
            <li>1. التأمين الإلزامي</li>
            <li>2. المسؤولية المدنية ضد الغير </li>
            <li>3. التأمين الشامل</li>
          </ul>
        }
        arabTitle={"تأمين السيارات والمركبات"}
        big
      />
      <Card
        frontImage={
          "https://cdn.pixabay.com/photo/2014/12/10/20/56/medical-563427_960_720.jpg"
        }
        engTitle={"Health Insurance"}
        engText={
          <ul>
            <li>1. Personal</li>
            <li>2. Family</li>
            <li>3. Groups</li>
          </ul>
        }
        arabText={
          <ul>
            <li>1. أفراد</li>
            <li>2. عائلات</li>
            <li>3. مجموعات</li>
          </ul>
        }
        arabTitle={"تأمين استشفاء "}
        big
      />
      <Card
        frontImage={"https://i.ibb.co/tJyGQ6L/Contractors-insurance-3.jpg"}
        engTitle="Contractors'all risks & Engineering insurance"
        engText={
          "All Risk and Erection All Risk provide cover against accidental damage to the construction works and protects against third party liabilities"
        }
        arabText={
          "يغطي جميع أنواع المخاطر التي تتعرض لها المشاريع الهندسية بالإضافة للمسؤولية المدنية تجاه الغير."
        }
      />
      <Card
        frontImage={"https://i.ibb.co/CHS7m22/fire.jpg"}
        engTitle="Fire insurance & Burglary"
        engText={
          <ul>
            <li>1. Fire Building & Contents, owners, neighbors...</li>
            <li>2. Allied perils...</li>
            <li>3. LM7</li>
            <li>4. Pecuniary insurance, burglary,</li>
          </ul>
        }
        arabTitle="تأمين الحريق ,وملحقاته"
        arabText={
          <ul>
            <li>1. الحريق</li>
            <li>2. العوامل الطبيعية</li>
            <li>
              3. تأمين السرقة، الأموال على الصندوق، الأموال المنقولة وإساءة
              الأمانة
            </li>
          </ul>
        }
        big
      />
    </div>
  );
};

export const CardsServices = () => {
  return (
    <div className="cards-container">
      <Card
        frontImage={
          "https://cdn.pixabay.com/photo/2016/08/25/20/14/crash-test-1620591_960_720.jpg"
        }
        engTitle={"Car & Vehicules"}
        engText={
          <ul>
            <li>
              1.TPL Bodiliy Injuries: covers bodily injuries caused to third
              parties.
            </li>
            <li>
              2.TPL Material Damage: covers material damages caused to third
              parties with a reliable assistance service.
            </li>
            <li>
              3.Motor All Risk: comprehensive car insurance cover witn a
              reliable assistance service.
            </li>
            <li>4.Orange Card: Syria & Arabic countries.</li>
          </ul>
        }
        arabText={
          <ul>
            <li>1. الأضرار الجسدية: يغطي الأضرار الجسدية. </li>
            <li>2. الأضرار المادية: يغطي الأضرار المادية للغير. </li>
            <li>3. التأمين الشامل: يغطي الآلية المؤمنة . </li>
            <li>4. البطاقة البرتقالية: سوريا والدول العربية. </li>
          </ul>
        }
        arabTitle={"تأمين السيارات والمركبات"}
      />
      <Card
        frontImage={
          "https://cdn.pixabay.com/photo/2014/12/10/20/56/medical-563427_960_720.jpg"
        }
        engTitle={"Medical Insurance"}
        engText={
          "Unlimited Financial limitation, all classes are available, full network across Lebanon witn an international coverage option, Guaranteed renewability as of day one, Congenital cases, Prosthesis due to accident or sickness, Work accident, Personal accident, Home care, Maternity, New born baby from day one.... besides the ambulatory, prescription drugs ,and doctor's visit. .."
        }
        arabText={
          <ul>
            <li>1. الاستشفاء والطوارئ والأعمال الطبية والأدوية</li>
            <li>
              2. ضمانة التجديد مدى الحياة ، تغطية الاجهزة الطبية والامراض
              السرطانية.
            </li>
            <li>
              3. تغطية الولادة ومضاعفاتها والمولود من اليوم الاول والامراض
              الخلقية.
            </li>
          </ul>
        }
        arabTitle={"تأمين استشفاء "}
      />
      <Card
        frontImage={"https://i.ibb.co/tJyGQ6L/Contractors-insurance-3.jpg"}
        engTitle="Contractors'all risks & Engineering insurance"
        engText={
          "All Risk and Erection All Risk provide cover against accidental damage to the construction works and protects against third party liabilities. / Machinery Breakdown and Deterioration of Stock provides cover against accidental breakdown ot machinery and the resulting deterioration ot stock"
        }
        arabText={
          "يغطي جميع أنواع المخاطر التي تتعرض لها المشاريع الهندسية بالإضافة للمسؤولية المدنية تجاه الغير. كما ويغطي  الأضرار التي ممكن تصيب الآليات والمواد الأولية أثناء وجودها في مكان العمل"
        }
      />
      <Card
        frontImage={"https://i.ibb.co/CHS7m22/fire.jpg"}
        engTitle="Fire insurance & Burglary"
        engText={
          <ul>
            <li>1. Fire Building & Contents, owners, neighbors...</li>
            <li>2. Allied perils...</li>
            <li>3. LM7</li>
            <li>4. Pecuniary insurance, burglary.</li>
          </ul>
        }
        arabTitle="تأمين الحريق ,وملحقاته"
        arabText={
          <ul>
            <li>1. الأبنية، المنازل والمصانع</li>
            <li>2. العوامل الطبيعية (هزات، زلازل، عواصف، فيضانات...)</li>
            <li>3. الحيران</li>
            <li>
              4. تأمين ضد السرقة، الأموال على الصندوق، الأموال المنقولة، إساءة
              الأمانة...
            </li>
          </ul>
        }
      />

      <Card
        frontImage={
          "https://images.pexels.com/photos/9462735/pexels-photo-9462735.jpeg?auto=compress&cs=tinysrgb&w=600"
        }
        engTitle="EXPAT INSURANCE"
        engText={
          <ul>
            <li>1.Expatriate Repatriation & Deportation (In case of Death)</li>
            <li>
              2.Coverage for permanent, partial or total disablement due to an
              accident.
            </li>
            <li>
              3.Repatriation costs in case the expatriate is forced to leave the
              country.
            </li>
          </ul>
        }
        arabTitle={"تأمين العمال الأجانب والخدم"}
        arabText={
          <ul>
            <li>1. الحالات المرضية والطوارىء والحوادث. </li>
            <li>
              2. الوفاة نتيجة حادث و أو العجز الكلي وكلفة النقل إلى الخارج .
            </li>
          </ul>
        }
      />
      <Card
        engTitle={" Workmen compensation"}
        engText={
          <ul>
            <li>
              1. Death, Permanent and Temporary Disabilities due to a work
              accident.
            </li>
            <li>
              2. Medical and hospital expenses, Rehabilitation due to a work
              accident.
            </li>
            <li>
              3.Salaries and wages accompanied with employee absence due to work
              related accidents.
            </li>
          </ul>
        }
        arabTitle="تأمين طوارئ العمل"
        arabText={
          <ul>
            <li>1. الوفاة، العجز، والمصاريف الطبية للموظفين أو للعاملين. </li>
            <li>2. الرواتب والأجور في حال التعطيل عن العمل. </li>
          </ul>
        }
        frontImage={
          "https://images.unsplash.com/photo-1594581835488-0b95b8b0bacd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
      />
      <Card
        frontImage={
          "https://i.ibb.co/0fGDcj7/21-Aftermath-Of-The-Read-Only.jpg"
        }
        engTitle="Political violence Insurance"
        engText={
          <ul>
            <li>1. Act ot terrorism.</li>
            <li>2. Sabotage.</li>
            <li>3. Strikes, riots, civil commotion.</li>
            <li>4. Malicious damage. </li>
            <li>5. Insurrection. </li>
            <li>6. Revolution or rebillion. </li>
            <li>7. War and/or civil war... </li>
          </ul>
        }
        arabTitle="تأمين العنف السياسي"
        arabText={
          <ul>
            <li>1. الارهاب.</li>
            <li>2. الشغب. </li>
            <li>3. الاضرابات. </li>
            <li>4. الحرب الداخلية والخارجية .</li>
            <li>4. الانقلاب .....</li>
          </ul>
        }
      />
      <Card
        engTitle={"Travel insurance"}
        engText={
          <ul>
            <li>1.Medical expenses occured during travel.</li>
            <li>
              2.Emergency medical evacuation, emergency medical repatriation.
            </li>
            <li>3. Loss of Passport, luggage.</li>
            <li>4. Trip cancellation, delay.</li>
            <li>5. Compassionate visit.</li>
            <li>6. Return of minor children.</li>
            <li>7. Dental emergency due to accident.</li>
          </ul>
        }
        frontImage={
          "https://cdn.pixabay.com/photo/2020/05/25/17/03/travel-5219496_960_720.jpg"
        }
        arabTitle="تأمين السفر"
        arabText={
          <ul>
            <li>1. المصاريف الطبية نتيجة حادث او الحالات الطارئة. </li>
            <li>2. فقدان الحقائب، جوازات السفر. </li>
            <li>3. إلغاء أو تأجيل الرحلة. </li>
            <li>4. الوفاة نتيجة حادث . </li>
            <li>5. إرجاع الأولاد القاصرين . </li>
          </ul>
        }
      />
      <Card
        engTitle="Marine Insurance"
        engText={
          <ul>
            <li>
              1.Cargo protection: Protects your cargo against any damage,
              accident or theft that may happen.
            </li>
            <li>
              2.Hull or Yacht protection: Protects your ship against accidents
              or damages including machinery, engine, and equipment breakdown.
            </li>
            <li>
              3.Third Party Liability: Protects you against damages caused to
              your crew, yourself, or others.
            </li>
          </ul>
        }
        frontImage="https://i.ibb.co/DggcsPF/cargo-ship.jpg"
        arabTitle={"تأمين النقل البحري"}
        arabText={
          <ul>
            <li>1. برا، بحرا، جوا. </li>
            <li>2. تأمين الأضرار الحاصلة للسفن أو اليخوت . </li>
            <li>3. تأمين المسؤولية المدنية للغير. </li>
          </ul>
        }
      />
      <Card
        frontImage={"https://i.ibb.co/4RHXNCY/careers.jpg"}
        arabTitle="تأمين المسؤولية المدنية"
        arabText={
          <ul>
            <li>
              1. الاضرار الجسدية والمادية : تجاه الاشخاص الاخرين او ممتلكاتهم.
            </li>
            <li>
              2. المسؤولية المهنية :(اطباء ، مهندسين ....) : من جراء ممارسة
              المهنة.
            </li>
          </ul>
        }
        engText={
          <ul>
            <li>
              1.Third party liability: This policy covers third party
              liabilities for bodily injuries and third party liabilities
              arising from material damages.
            </li>
            <li>
              2.Professional liability insurance (Engineers, Doctors...):
              Protects professionals against claims initiated by their clients
              and resulting trom the practice of their profession.
            </li>
          </ul>
        }
        engTitle={"Civil liability Insurance"}
      />
    </div>
  );
};

export default Cards;
