import React from "react";
import Footer from "../../components/Footer/Footer";
import { ContactForm } from "../../components/Form/Form";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="header-margin">
      <div className="contact">
        <h2>Location</h2>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.5141284105853!2d35.50518601520982!3d33.85063868066134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17f0e51d0c0b%3A0x5dbb995b2cc83381!2sStar%20gate%20insurance%20consultants!5e0!3m2!1sen!2slb!4v1673206058269!5m2!1sen!2slb"
            title="map"
          ></iframe>
        </div>
        <div className="section">
          <div className="text">
            Haret Hreik - Main St. - Facing Scientific Library - 1st Floor. Hara
            424 Building
          </div>
          <div className="text">
            <div className="number">
              <ion-icon name="call-outline"></ion-icon> 01 544 465
            </div>
            <div className="number">
              <ion-icon name="phone-portrait-outline"></ion-icon>76655882
            </div>
            <div className="number">
              <ion-icon name="briefcase-outline"></ion-icon>25-5036
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <h2>To Suggest</h2>
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
