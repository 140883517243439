import React, { useState } from "react";
import { BigFormModal } from "../Popups/Modal";
import "./Certification.css";
import useWindowDimensions from "../../utils/windowSize";

const Certification = ({ img }) => {
  const width = useWindowDimensions().width;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="certification">
      <div className="content">
        <img
          src={img}
          className="certification-img"
          onClick={() => {
            if (width > 760) {
              setIsOpen(true);
            }
          }}
          alt=""
        />
      </div>
      <BigFormModal open={isOpen} onClose={() => setIsOpen(false)}>
        <img src={img} className="certification-img" />
      </BigFormModal>
    </div>
  );
};

export default Certification;
