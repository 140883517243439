import React from "react";
import "./Cards.css";
import { Link } from "react-router-dom";

const Card = ({
  frontImage,
  backImage,
  engText,
  arabText,
  engTitle,
  arabTitle,
  big,
}) => {
  return (
    <a className={`card ${big ? "small-card" : ""}`}>
      <Link to={"/services"}>
        <div
          className="front"
          style={{
            backgroundImage: `url(${frontImage})`,
          }}
        >
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisi.</p> */}
        </div>
        <div
          className="back"
          style={{
            backgroundImage: `url(${backImage})`,
          }}
        >
          <div className="text">
            <div className="eng">
              <h3 className="text-3xl">{engTitle}</h3>
              <p>{engText}</p>
            </div>
            <div className="arab">
              <h3>{arabTitle}</h3>
              <p>{arabText}</p>
            </div>
            {/* <button className="button">Click Here</button> */}
          </div>
        </div>
      </Link>
    </a>
  );
};

export default Card;
