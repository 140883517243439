import React from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div>
      <ul>
        <Link to={"/"} className="link">
          <li>
            <a className={`${path == "/" ? "active" : ""}`}>Home</a>
          </li>
        </Link>
        <Link to={"/about"} className="has-dropdown link">
          <li>
            <a className={`${path == "/about" ? "active" : ""} `}>About</a>
          </li>
        </Link>
        <Link to={"/services"} style={{ textDecoration: "none" }}>
          <li>
            <a className={`${path == "/services" ? "active" : ""}`}>Services</a>
          </li>
        </Link>
        <Link to={"/contact"} style={{ textDecoration: "none" }}>
          <li>
            <a className={`${path == "/contact" ? "active" : ""}`}>
              Contact Us
            </a>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default Navbar;
