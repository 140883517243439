import React from "react";
import "./People.css";

const People = ({ img, name, role }) => {
  return (
    <div className="people">
      <div className="content">
        <img src={img} className="people-img" alt="" />
        <h2>
          {name}
          <strong>{role}</strong>
        </h2>
      </div>
    </div>
  );
};

export default People;
