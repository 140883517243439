import React from "react";
import { useState, useEffect } from "react";
import { CardsServices } from "../../components/Cards/Cards";
import Footer from "../../components/Footer/Footer";
import Form from "../../components/Form/Form";
import "./Services.css";

const Services = () => {
  const [starRotation, setStarRotation] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      let y = window.scrollY;
      setStarRotation(y / 3);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="header-margin">
        <img
          className="pages-star"
          id="star"
          src="./pictures/star.png"
          style={{
            transform: `rotate(${starRotation}deg)`,
          }}
          alt="star"
        />
        <div className="services">
          <h2>Our Services</h2>
          <CardsServices />
        </div>
        <div className="form-wrapper">
          <h2>Apply Now</h2>
          <Form />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
