import Certification from "../../components/Certification/Certification";
import Footer from "../../components/Footer/Footer";
import People from "../../components/People/People";
import Slider from "../../components/Slider/Slider";
import Value from "../../components/Values/Value";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="header-margin">
        <div className="about" id="top">
          <h2>Our Vision</h2>
          <div className="field">
            <img src="https://i.ibb.co/sQWZGz2/vision.jpg" alt="vision" />
            <div className="caption">
              <p className="text">
                Our Vision is to become one of the main Players of Lebanon’s
                insurance brokerage markets, by providing sound and professional
                advice to our clients with an ultimate customer service and risk
                management support, that goes beyond the insurance policy cover.
              </p>
              <p className="text ar">
                رؤيتنا هي أن تصبح شركتنا من الشركات الرائدة في أسواق وساطة
                التأمين في لبنان عبر تقديم الاستشارات العلمية والمهنية لزبائننا،
                من خلال فريق عمل مُدرب، بالأضافة الى دراسة إدارة المخاطر التي
                تتجاوز تغطية بوليصة التأمين.
              </p>
            </div>
          </div>
          <h2>Our Profile</h2>
          <div className="field">
            <img
              src="https://i.ibb.co/gMZqfHL/image-profile.jpg"
              alt="vision"
            />
            <div className="caption">
              <p className="text">
                The Insurance industry has progressed as a route of safeguarding
                the interest of people from loss and uncertainty. As the role of
                insurance became dynamic in our everyday life, STARGATE S.A.R.L
                established by Mr. Salah Joumaa & Zuhair Al hajj Hassan, proudly
                offers you different types of insurance that you might need,
                through its thirty years of experience in the insurance
                consultation market, a license number 331 from the ministry of
                economy and trade, its acknowledged credibility, integrity and
                reliability, and finally by its involvement and contribution
                with leading insurance companies in Lebanon to satisfy and
                provide all type of insurances for its insured clients.
              </p>
              <p className="text ar">
                بما أن التأمين اصبح حاجة ضرورية ولازمة في عصرنا هذا وقد تحول الى
                اختصاص علمي مستقل يُرفد بتراكم خبرات عملية بالاضافة الى ضرورة
                وجود مصداقية مشهود لها عبر السنين ، لكل هذا يسر شركة ستارغيت
                ش.م.م. بخبرتها على مدى خمسة وعشرون سنة في مجال الوساطة
                والاستشارات التأمينية والحائزة على ترخيص وزارة الاقتصاد والتجارة
                رقـم 331 وبتعاقدها وبتعاملها مع اكبر واهم شركات التأمين في لبنان
                التي تفتخر باختيارها لها، لتتناسب مع كل حاجات المضمونين لديها،
                بأن تضع خبرتها بتصرفكم من خلال السيدين صلاح جمعة وزهير الحاج حسن
                ، لتعرض عليكم جميع أنواع التأمينات التي قد تحتاجونها.
              </p>
            </div>
          </div>
          <h2>Our Values</h2>
          <div className="values">
            <Value
              engTitle={"Intergrity"}
              engP={
                "We conduct our operations with the highest ethical standards, and develop mutual trust with our policyholders, agents."
              }
              arabTitle={"النزاهة"}
              arabP={
                "نحن ملتزمون بأن ندير شركتنا بأعلى المعايير الأخلاقية ، ونعمل على تطوير الثقة المتبادلة مع زبائننا ووكلائنا على السواء."
              }
            />
            <Value
              engTitle={" EXCELLENCE"}
              engP={
                "We are dedicated to continuously improve our products, services, and procedures."
              }
              arabTitle={"التفوق"}
              arabP={
                "نحن ملتزمون بالتحسين المستمر لمنتجاتنا وخدماتنا وإجراءاتنا لنحقق اعلى المعايير في تقديم افضل العقود التأمينية."
              }
            />
            <Value
              engTitle={"CUSTOMER FOCUS"}
              engP={
                "We are committed to adding value to each client relationship by listening, understanding, and prioritizing their security and interests."
              }
              arabTitle={"التركيز على الزبائن"}
              arabP={
                "نحن ملتزمون بإضافة قيمة للعلاقة مع زبائننا من خلال الاستماع ، فهم ، وتحديد أولويات أمنهم ومصالحهم بحيث نشكل جميعاً عائلة واحدة ."
              }
            />
            <Value
              engTitle={"PROMPTNESS"}
              engP={
                "We make sure your claims and requests are processed fairly and quickly."
              }
              arabTitle={" السرعة"}
              arabP={
                "نحن ملتزمون بان تتم معالجة الحوادث والمطالبات التأمينية بشكل عادل وسريع يحفظ حقوق المؤمن وشركة التأمين على حد سواء."
              }
            />
            <Value
              engTitle={"DIVERSITY"}
              engP={
                "We know it takes people with different ideas, strengths, interests, and cultural backgrounds to make our company succeed."
              }
              arabTitle={"التنوع"}
              arabP={
                "نحن ملتزمون بان نتعامل مع زبائننا بمعزل عن الافكار والعقائد والخلفيات الفكرية التي يحملون لجعل شركتنا من الشركات الاوائل في لبنان."
              }
            />
          </div>
          <h2>Our Portfolio</h2>
          <div className="certificates">
            <Certification
              img={"https://i.ibb.co/5sWgjdn/photo-2023-03-24-18-58-22.jpg"}
            />
            <Certification img={"https://i.ibb.co/6gB9gpJ/new.jpg"} />
            <Certification
              img={
                "https://i.ibb.co/XsNKQw0/Whats-App-Image-2019-09-08-at-6-36-2.jpg"
              }
            />
            <Certification img={"https://i.ibb.co/s9nhH8k/cer.jpg"} />
            <Certification img={"https://i.ibb.co/YXN61QY/1.jpg"} />
            <Certification img={"https://i.ibb.co/RvZjW0N/2.jpg"} />
            <Certification img={"https://i.ibb.co/DKHqhsn/3.jpg"} />
            <Certification img={"https://i.ibb.co/RHyZZdJ/certificate.jpg"} />
            <Certification img={"https://i.ibb.co/WzXVmjd/certificate-2.jpg"} />
            <Certification img={"https://i.ibb.co/z65MrTP/C.jpg"} />
            <Certification img={"https://i.ibb.co/zFn9QJ0/Property-ins.jpg"} />
            <Certification
              img={"https://i.ibb.co/Gc1Cv1b/Property-ins-2.jpg"}
            />
          </div>
          <h2>Our Team</h2>
          <div className="team">
            <People
              img={"https://i.ibb.co/NmxPnV8/photo-2023-01-25-19-59-31.jpg"}
              name="Salah Joumaa"
              role="Owner /General Manager"
            />
            <People
              img={
                "https://i.ibb.co/gjtQFLf/zoheir-el-hajj-hassan-General-Manager.jpg"
              }
              name="Zouheir El Hajj Hassan"
              role="Owner /General Manager"
            />
            <People
              img={
                "https://i.ibb.co/mHjZVSD/hanadi-sbeity-Manager-Undrewriting-Dept.jpg"
              }
              name="Hanadi Sbeity"
              role="Manager / Undrewriting Department"
            />
            <People
              img={
                "https://i.ibb.co/0mZDhTv/mona-bahjat-Officier-Undrewriting-Dept-Motor-Expatriate-Travel.jpg"
              }
              name="Mouna Bahjaat"
              role="Officer Underwriting Department.-Motor-Expatriate-Travel"
            />

            <People
              img={"https://i.ibb.co/GnSWR4j/photo-2023-05-05-21-33-59.jpg"}
              name="Mouna Fadel"
              role="Underwriting Department.Motor"
            />
            <People
              img={"https://i.ibb.co/160sR9h/photo-2023-05-05-21-33-38.jpg"}
              name="Madhi Darwish"
              role="Collection Department"
            />
            <People
              img={
                "https://cdn.pixabay.com/photo/2014/07/06/13/55/calculator-385506_960_720.jpg"
              }
              name="Batoul Al Hussaini"
              role="Accounting Department"
            />
            <People
              img={
                "https://images.unsplash.com/photo-1554224155-1696413565d3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              }
              name="Iman Sarhan"
              role="Underwriting Department.-Motor-Expatriate-Travel"
            />
          </div>
          <h2>INSURANCE COMPANIES</h2>
          <div className="slider-wrapper">
            <Slider />
          </div>
          <h2>Our Clients</h2>
          <div className="clients">
            <ul>
              <li>Al Mayadeen Channel</li>
              <li>Union Of Municipality Of El Dahyeh El Janoubiyeh</li>
              <li>Mleeta Touristic Center</li>
              <li>El Ghobeiry Municipality</li>
              <li>Borj el Barajneh Municipality</li>
              <li>Union Of Municipality Of West Baalbeck</li>
              <li>Chmestar Municipality</li>
              <li>Union Of Municipality Of Eklim El Toufah</li>
              <li>Jbaa Municipality</li>
              <li>Beit Shama Municipality</li>
              <li>Ste. Scout Al Mahdy</li>
              <li>Ste. Al El Beit</li>
              <li>Doctors of Al Rassoul Hospital</li>
              <li>Doctors of Al Sahel Hospital</li>
              <li>Doctors of Al Zahraa Hospital</li>
              <li>Doctors of Saint-George Hospital</li>
              <li>Al Tourath company for Engineering</li>
              <li>Ste. Metal For Contracting & Trading</li>
              <li>Techno Motors Company</li>
              <li>Ste. Khalifeh For Livestock Trade</li>
              <li>Bloom Stores</li>
              <li>PRIMO INTERNATIONAL S.A.R.L</li>
            </ul>
            <ul className="arab">
              <li>قناة الميادين</li>
              <li>اتحاد بلديات الضاحية الجنوبية</li>
              <li>معلم مليتا الجهادي</li>
              <li>بلدية الغبيري</li>
              <li>بلدية برج البراجنة</li>
              <li>اتحاد بلديات غربي بعلبك</li>
              <li>بلدية شمسطار</li>
              <li>اتحاد بلديات اقليم التفاح</li>
              <li>بلدية جباع</li>
              <li>بلدية بيت شاما</li>
              <li>جمعية كشافة المهدي</li>
              <li>جمعية آل البيت</li>
              <li>أطباء مستشفى الرسول الأعظم</li>
              <li>أطباء مستشفى الساحل</li>
              <li>أطباء مستشفى الزهراء</li>
              <li>أطباء مستشفى السان جورج</li>
              <li>شركة التراث للهندسة</li>
              <li>شركة تكنو موتورز</li>
              <li>شركة خليفة لتجارة المواشي</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
