import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { UilMultiply } from "@iconscout/react-unicons";
import "./Modal.css";

export const BigFormModal = ({ open, onClose, children }) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className="form-modal"
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 300,
    }}
  >
    <Fade in={open}>
      <div className="big-form-modal-wrapper">
        <button
          className={"button-no-styles big-form-modal-close"}
          onClick={onClose}
        >
          <UilMultiply color={"white"} />
        </button>
        <div className="big-modal-container">{children}</div>
      </div>
    </Fade>
  </Modal>
);
